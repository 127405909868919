import React from 'react'
import { Link } from 'gatsby'

import imgHero from '../assets/images/home-hero.png'

import SEO from '../components/SEO'
import Header from '../components/Header'

import { colors, theme } from '../constants/theme'
import { featuredProjects, otherProjects } from '../constants/content'

import ProjectsPageLayout from '../styles/ProjectsPageLayout'
import { FeaturedProjects, ProjectsSquare } from '../styles/PageLayout'
import { HeadlineM, TitleM, SubtitleM, ParagraphM, ParagraphS } from '../styles/Typography'

const ProjectsPage = () => (
  <>
    <SEO title="Mint and Square | Projekti" image={imgHero} />
    <Header
      logoBackground={colors.red}
      logoBorder={colors.red}
      linkText={colors.white}
      menuLines={colors.red}
    />
    <ProjectsPageLayout>
      <div className="section-one">
        <div className="text-left">
          <div className="title">
            <ParagraphM color={colors.white}>Projekti</ParagraphM>
          </div>
          <div className="headline">
            <HeadlineM color={colors.white}>Nekaj naših</HeadlineM>
            <HeadlineM color={colors.red} font={theme.fontSecondary}>
              najljubših projektov
            </HeadlineM>
          </div>
        </div>
        <div className="text-right">
          <ParagraphM color={colors.white} weight="300">
            Gradimo čudovite zgodbe, ki prodajajo odlične produkte
          </ParagraphM>
        </div>
      </div>

      <div className="section-two">
        <FeaturedProjects>
          {featuredProjects.map((project, index) => (
            <Link to={project.link} key={index} className="project">
              <div className="project-image">
                <img src={project.img} alt={project.title} />
              </div>
              <TitleM>{project.title}</TitleM>

              <ParagraphM font={theme.fontSecondary} className="marquee">
                {project.tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                  </span>
                ))}
              </ParagraphM>
            </Link>
          ))}
        </FeaturedProjects>
      </div>

      <div className="section-three">
        {otherProjects.map((project, index) => (
          <Link to={project.link} key={index}>
            <ProjectsSquare>
              <img src={project.img} alt={project.title} />
              <div className="box">
                <div className="text">
                  <SubtitleM color={colors.white}>{project.title}</SubtitleM>
                  <ParagraphS color={colors.white}>{project.tag}</ParagraphS>
                </div>
                <div className="overlay"></div>
              </div>
            </ProjectsSquare>
          </Link>
        ))}
      </div>
    </ProjectsPageLayout>
  </>
)

export default ProjectsPage
