import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideUp = keyframes`
  0% {
    transform: translateY(200%)
  }
  100% {
    transform: translateY(0)
  }
`

const grow = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`

const ProjectsPageLayout = styled.div`
  width: 100%;

  .section-one {
    display: grid;
    align-items: end;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
    background-color: ${(props) => props.theme.backgroundPurple};
    height: 85vh;
    min-height: 730px;
    padding: 6rem 9rem;

    @media (max-width: ${breakpoints.screenLG}) {
      height: 50vh;
      padding: 6rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      grid-template-columns: 1.8fr 1fr;
      height: 60vh;
      min-height: 600px;
      padding: 6rem 1.5rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: 1fr;
      height: calc(100vh - 120px);
      min-height: calc(100vh - 120px);
      padding-bottom: 5rem;
    }

    .text-left {
      padding-bottom: 4rem;

      @media (max-width: ${breakpoints.screenL}) {
        padding-bottom: 3rem;
      }

      p {
        position: relative;
        max-width: 10rem;
        padding-bottom: 0.5rem;
        margin-bottom: 2rem;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 3px;
          background-color: ${(props) => props.theme.backgroundRed};
          transform-origin: left;
          transform: scale(0);
          animation: ${grow} 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 0.2s;
        }
      }
    }

    .text-right {
      align-self: end;
      margin-left: auto;
      text-align: right;
      opacity: 0;
      animation: ${fadeIn} 0.8s ease forwards 0.4s;

      p {
        max-width: 20rem;
      }
    }

    .headline {
      overflow: hidden;
    }

    h1 {
      transform: translateY(200%);
      animation: ${slideUp} 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 0.2s;
    }

    .title {
      opacity: 0;
      animation: ${fadeIn} 0.4s ease forwards 0.2s;
    }
  }

  .section-two {
    padding: 10rem 9rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 8rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 4rem 1.5rem;
    }
  }

  .section-three {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5rem;
    padding: 0 9rem 12rem;

    @media (max-width: ${breakpoints.screenLG}) {
      grid-template-columns: repeat(3, 1fr);
      padding: 0 3rem 12rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      column-gap: 3rem;
      padding: 0 1.5rem 10rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: repeat(2, 1fr);
    }

    a {
      @media (min-width: ${breakpoints.screenLG}) {
        &:nth-child(4n + 1) {
          margin-top: 6rem;
        }

        &:nth-child(4n + 2) {
          margin-top: 4rem;
        }

        &:nth-child(4n + 3) {
          margin-top: 2rem;
        }
      }

      @media (max-width: ${breakpoints.screenLG}) {
        &:nth-child(3n + 1) {
          margin-top: 6rem;
        }

        &:nth-child(3n + 2) {
          margin-top: 4rem;
        }

        &:nth-child(3n + 3) {
          margin-top: 2rem;
        }
      }

      @media (max-width: ${breakpoints.screenSM}) {
        &:nth-child(2n + 1) {
          margin-top: 4rem;
        }

        &:nth-child(2n + 2) {
          margin-top: 2rem;
        }
      }
    }
  }
`

export default ProjectsPageLayout
